.app {
  display: flex;
  flex-direction: row;
}

.charts {
  flex: 1 0;
}

.control-panel {
  flex: 1 0;
}
